import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "100%",
  height: "1080px",
  frameborder: "0",
  src: "https://erp.tappware.com/accounting/reports/11"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", _hoisted_1))
}